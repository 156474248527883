<template>
  <div class="k2-input-label">
    <div :class="['k2-input-label-label', themeLabelColor]">
      <!-- @slot The content to display as the label -->
      <slot />

      <!-- @slot The content to display hover info -->
      <slot
        v-if="hasExplainer"
        name="explainer"
      >
        <span class="k2-input-label-explainer">
          <komodo-tooltip
            :max-width="explainerTooltipMaxWidth"
          >
            <template #hoverSlot>
              <komodo-icon
                :size="IconSize.XSmall"
                :icon="explainerIcon"
                color="text_quaternary_text"
              />
            </template>
            <template #textSlot>
              {{ explainerTooltip }}
            </template>
          </komodo-tooltip>
        </span>
      </slot>
    </div>
    <div
      v-if="hasSubtext"
      class="k2-input-label-subtext"
    >
      <!-- @slot The content to display as the subtext -->
      <slot name="subtext" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, useSlots } from 'vue'

import KomodoIcon from 'komodo2/components/KomodoIcon.vue'
import KomodoTooltip from 'komodo2/components/KomodoTooltip.vue'

import { IconSize, InputState } from 'komodo2/types.ts'

export default {
  name: 'KomodoInputLabel'
}
</script>

<script setup lang="ts">
interface Props {
  /**
   * Indicates what state the label should be rendered in
   */
  state?: InputState
  /**
   * The icon that show explainer tooltip
   */
  explainerIcon?: string
  /**
   * The tooltip that show on explainer hover. Explainer will hide if this is left empty.
   */
  explainerTooltip?: string
  /**
   * Max width of the tooltip in pixels
   */
  explainerTooltipMaxWidth?: number
}

const props = withDefaults(defineProps<Props>(), {
  state: InputState.Default,
  explainerTooltip: undefined,
  explainerIcon: 'info',
  explainerTooltipMaxWidth: undefined
})

const slots = useSlots()

const themeLabelColor = computed(() => {
  return (props.state === InputState.Error ? 'k2-label-error' : props.state === InputState.Disabled ? 'k2-label-disabled' : '')
})

const hasSubtext = computed(() => {
  return !!slots.subtext
})

const hasExplainer = computed(() => {
  return !!props.explainerTooltip || !!slots.explainer
})
</script>

<style lang="scss" scoped>
.k2-input-label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 4px;

  @include k2-media-breakpoint(sm-and-down) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .k2-input-label-label {
    @include k2-font-text-text-med;
    @include k2-color-text-primary-text;

    display: flex;
  }

  .k2-label-error {
    @include k2-color-error-base;
  }

  .k2-label-disabled {
    @include k2-color-text-disabled-text;
  }

  .k2-input-label-subtext {
    @include k2-font-text-caption;
    @include k2-color-text-secondary-text;

    padding-left: 4px;

    @include k2-media-breakpoint(sm-and-down) {
      padding-left: 0;
    }
  }

  .k2-input-label-explainer {
    display: inline-flex;
    align-items: center;
    padding-left: 4px;

    :deep(.hover-slot) {
      display: inline-flex;
    }
  }
}
</style>
